import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { CopyToClipboardDirective } from '@shared/directives/copy-to-clipboard.directive';
import { SortingHeaderComponent } from '@shared/components/sorting/sorting-link/sorting-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomConfirmModalComponent } from './components/modal/custom-confirm-modal/custom-confirm-modal.component';
import { CustomDeleteModalComponent } from './components/modal/custom-delete-modal/custom-delete-modal.component';
import { DocumentReplicationModalComponent } from '@shared/components/modal/document-replication-modal/document-replication-modal.component';
import { DeleteItemModalComponent } from './components/modal/delete-item-modal/delete-item-modal.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PaperworkServiceProxy, PaperworkStateServiceProxy } from '@shared/service-proxies/service-proxies';
import { SearchbarComponent } from './components/filters/searchbar/searchbar.component';
import { PaperworkFiltersComponent } from '@shared/components/filters/paperwork-filters/paperwork-filters.component';
import { AutocompleteLibModule } from '@node_modules/angular-ng-autocomplete';
import { TagInputModule } from '@node_modules/ngx-chips';
import { NgSelectModule } from '@node_modules/@ng-select/ng-select';
import { BasicConfirmModalComponent } from './components/modal/basic-confirm-modal/basic-confirm-modal.component';
import { SyncDocumentsModalComponent } from './components/modal/sync-documents-modal/sync-documents-modal.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    imports: [CommonModule, RouterModule, NgxPaginationModule, ReactiveFormsModule, FormsModule, AutocompleteLibModule, TagInputModule, NgSelectModule, MatSelectModule],
    declarations: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        CustomConfirmModalComponent,
        CustomDeleteModalComponent,
        DocumentReplicationModalComponent,
        DeleteItemModalComponent,
        BreadcrumbComponent,
        SearchbarComponent,
        PaperworkFiltersComponent,
        BasicConfirmModalComponent,
        SyncDocumentsModalComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        DocumentReplicationModalComponent,
        BreadcrumbComponent,
        SearchbarComponent,
        PaperworkFiltersComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppSessionService, AppUrlService, AppAuthService, AppRouteGuard, LayoutStoreService, PaperworkServiceProxy, PaperworkStateServiceProxy],
        };
    }
}
